

import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';

import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';
import type UserService from '@/modules/user/user.service';
import { SettingsGeneralService } from '@/modules/settings/settings-general.service';
import IHistoryItem from '../interfaces/history-item.interface';

export interface TableData {
    id: number,
    name: string,
    rank: number | null,
    reviews: number | null,
    myHotel: boolean,
    borderColor?: string,
}

type SortBy = 'rank' | 'reviews';

@Component
export default class RankingHistoryTable extends Vue {
    @inject(KEY.UserService) private userService!: UserService;
    @inject(KEY.SettingsGeneralService) private settingsGeneralService!: SettingsGeneralService;

    @Prop({
        type: Array,
        default: null,
    })
    private data!: IHistoryItem[] | null;

    @Prop({
        type: Object,
        default: () => ({}),
    })
    public hiddenGraphs!: Record<number, boolean>;

    private sortBy: SortBy = 'rank';
    private sortOrder: boolean = true;

    handleSortClick(sortBy: SortBy) {
        if (sortBy === this.sortBy) {
            this.sortOrder = !this.sortOrder;
        } else {
            this.sortBy = sortBy;
            this.sortOrder = false;
        }
    }

    get tableData(): TableData[] {
        const { currentHotelId } = this.userService;

        if (!this.data) {
            return [];
        }

        return this.data
            .map((item, index) => ({
                id: item.id,
                name: item.name,
                rank: item.primary,
                reviews: item.secondary,
                myHotel: currentHotelId ? item.id === currentHotelId : false,
                borderColor: this.getColor(index, item.id),
            }))
            .sort((a, b) => {
                if (a[this.sortBy] === null) {
                    return 1;
                }

                if (b[this.sortBy] === null) {
                    return -1;
                }

                if (!this.sortOrder) {
                    return a[this.sortBy]! > b[this.sortBy]! ? 1 : -1;
                }

                return a[this.sortBy]! < b[this.sortBy]! ? 1 : -1;
            });
    }

    getSortOrder(sortBy: SortBy) {
        if (sortBy === this.sortBy) {
            return this.sortOrder;
        }

        return 1;
    }

    getColor(index: number, hotelId: number) {
        const { currentHotelId } = this.userService;
        const { chartColors } = this.settingsGeneralService;

        if (chartColors === null) {
            return '#000000';
        }

        if (!currentHotelId || currentHotelId !== hotelId) {
            return chartColors[index % chartColors.length];
        }

        return CURRENT_HOTEL_GRAPH_COLOR;
    }
}
