
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import PopupDateFilter from '../filters/popup-date.filter';
import type RankingHistoryService from '../ranking-history.service';

@Component({
    components: {
        // DayChanger,
    },
    filters: {
        PopupDateFilter,
    },
})
export default class RankingHistoryHeader extends Vue {
    @inject(KEY.RankingHistoryService) private rankingHistoryService!: RankingHistoryService;

    get updateDate() {
        return this.rankingHistoryService.updateDate;
    }
}
